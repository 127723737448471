.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

.page-header-alt {
  padding: 0 25px 0 25px !important;
}

.gray-subheading {
  vertical-align: middle;
  font-weight: 400;
  color: #818181;
  font-size: 20px;
}
.small-text {
  font-size: 14px;
  font-weight: 400;
  color: #818181;
  margin-left: 10px;
}

.theme-blue {
  /* color: #133fe3; */
  color: #3e79f7;
}

.text-blue {
  color: #3e79f7;
}

.theme-blue-background {
  color: #3e79f7;
}

.ant-select-arrow {
  top: 46%;
  right: 11px;
  width: 16px;
}

.send_first_sms {
  text-align: center;
  margin-top: 20px;
}
